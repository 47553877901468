import Vue from 'vue';

const app = new Vue({
  el: '.page-users',
  data: {
    inviteUser: {
      email: '',
      name: '',
    },
  },
  computed: {
    isInviteButtonEnabled() {
      return this.inviteUser.email.length > 0 && this.validateEmail(this.inviteUser.email)
    },
  },
  methods: {
    validateEmail(value) {
      return /^\S+@\S+\.\S+$/.test(value)
    },
    openInviteModal() {
      this.inviteUser = { email: '', name: '' }
      $('#invite-user-modal').modal('show')
    },
  },
})