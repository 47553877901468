$(document).ready(() => {
  $('.users-table tbody tr').click(function () {
    const id = $(this).data('id')
    const pathname = window.location.pathname

    if (pathname.includes('users')) {
      window.location = `/users/${id}`
    }

    if (pathname.includes('search')) {
      window.location = `/users/${id}`
    }
  })
})
