import './css/_custom';
import './css/main';
import './css/utilities';
import '../common/styles/components/_footer';
import '../common/styles/components/_tooltip';
import '../common/styles/components/_badge';
import '../common/styles/components/_button';
import '../boast/styles/pages/_user-search';
import '../boast/styles/pages/_user';
import '../boast/styles/pages/_teams';
import '../boast/styles/pages/_skills';
import '../boast/styles/pages/_login';
import '../boast/styles/components/_users-table';

import './js/main';
import '../common/shared';
import '../boast/opportunity-skills';
import '../boast/shared';
import '../boast/user';
import '../boast/user-vacation';
import '../boast/teams';
import '../boast/skills';
import '../boast/users';
import '../boast/user-table';
import '../boast/login';
import '../boast/forgotPassword';
import '../boast/resetPassword';
import '../boast/signup';
