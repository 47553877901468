import Vue from 'vue'

import { VTooltip, VPopover } from 'v-tooltip'

Vue.directive('tooltip', VTooltip)
Vue.component('v-popover', VPopover)

const validation = require('../../src/validation')
const axios = require('axios')

const { skillLevelBadgeClass, skillLevelDescription } = require('../../src/skillLevels')

Vue.component('alert-message', {
  props: ['message', 'state'],
  data: function () {
    return {
      successState: 'success',
      successClass: 'row g-0 alert alert-success alert-dismissable fade show',
      errorClass: 'row g-0 alert alert-danger alert-dismissable fade show',
      successIcon: '#99E0B6',
      errorIcon: '#FF6E52'
    }
  },
  template: '<div :class="[state==successState ? successClass : errorClass]" role="alert">' +
              '<div class="col col-lg-1 d-flex align-items-center justify-content-center">'+
                '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" :fill="[state==successState ? successIcon : errorIcon]" class="avatar avatar-rounded-circle bi bi-check-circle-fill" viewBox="0 0 16 16">'+
                  '<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>'+
                '</svg>' +
              '</div>'+
              '<div class="col align-self-center">'+
                '{{message}}'+
              '</div>'+
              '<div class="col col-lg-1 d-flex align-items-center justify-content-end">'+
                '<i class="bi bi-x" data-bs-dismiss="alert" aria-label="Close"></i>' +
              '</div>' +
            '</div>'
})

const app = new Vue({
  el: '.page-user',
  data: {
    editing: false,
    isSubmittingForm: false,
    user: {},
    customSkillLevel: {},
    skillLevels: null,
    initialPhotoUrl: null,
    photoUrl: null,
    wasEdited: {},
    isHourlyRateMasked: true,
    errorList: {},
    userId: null,
    userRole: null,
    action: null,
    alertMessages: [],
    approvalStatus: null,
    skillCategories: [],
    AVAILABILITY_DESCRIPTIONS: [],
    tooltipText: '',
  },
  beforeMount() {
    // Load the initial photoUrl value from the value specified in the Nunjucks template
    this.initialPhotoUrl = $('.profile__photo').attr('src')
    this.photoUrl = this.initialPhotoUrl

    if (this.$el.querySelector('[ref="customSkillLevel"]')) {
      this.customSkillLevel = JSON.parse(this.$el.querySelector('[ref="customSkillLevel"]').value);
    }

    if (this.$el.querySelector('[ref="userRole"]')) {
      this.userRole = this.$el.querySelector('[ref="userRole"]').value;
    }

    if (this.$el.querySelector('[ref="userId"]')) {
      this.userId = this.$el.querySelector('[ref="userId"]').value;
    }

    if (this.$el.querySelector('[ref="user"]')) {
      this.user = JSON.parse(this.$el.querySelector('[ref="user"]').value);
    }

    if (this.$el.querySelector('[ref="skillCategories"]')) {
      this.skillCategories = JSON.parse(this.$el.querySelector('[ref="skillCategories"]').value);
    }

    if (this.$el.querySelector('[ref="AVAILABILITY_DESCRIPTIONS"]')) {
      this.AVAILABILITY_DESCRIPTIONS = JSON.parse(this.$el.querySelector('[ref="AVAILABILITY_DESCRIPTIONS"]').value);
    }
    
    this.resetSkillLevels()
    
  },
  computed: {
    errors: {
      get: function () {
        if (Object.keys(this.errorList).length === 0) {
          // this is to disable the automatic loading of error messages on initial click of edit button
          // validate only fields that are edited
          const form_errors = validation.errors(validation.developerProfile, this.user)
          let validation_errors = {}
          for (const error_key in form_errors) {
            if (this.wasEdited[error_key] === undefined) {
              validation_errors[error_key] = []
            } else {
              validation_errors[error_key] = form_errors[error_key]
            }
          }
          this.errorList = validation_errors
        } else {
          // on succeeding updates upon save, validate all fields edited and required
          this.errorList = validation.errors(validation.developerProfile, this.user)
        }
        return this.errorList
      },
      set: function (validation_errors) {
        this.errorList = validation_errors
      },
    },
    hasErrors() {
      return Object.values(this.errors).some((errors) => errors.length > 0)
    },
    isDisabled() {
      return this.isSubmittingForm || this.hasErrors
    },
    interestedSkillLevels() {
      return _(Object.values(this.skillLevels))
        .filter((skillLevel) => skillLevel.interested)
        .orderBy('level', 'desc')
        .value()
    },
  },
  methods: {
    edit(e) {
      e.preventDefault()
      this.alertMessages.splice(0, this.alertMessages.length)
      $('#userAttributes_chosen .search-field input').css('width', '100%')
      this.editing = true
    },
    cancelEditing() {
      this.alertMessages.splice(0, this.alertMessages.length)
      this.editing = false
      this.wasEdited = {}

      if (this.$el.querySelector('[ref="user"]')) {
        this.user = JSON.parse(this.$el.querySelector('[ref="user"]').value);
      } else {
        this.user = {}
      }

      this.errorList = {}
      this.resetSkillLevels()

      // reset photoUrl to initial value
      this.photoUrl = this.initialPhotoUrl
    },
    getDeveloperFormData() {
      const form = document.getElementById("developer-form")
      let data = new FormData(form);
      data.set('action', this.action)
      if (this.approvalStatus != null) {
        data.set('approved', this.approvalStatus)
      }
      if (this.wasEdited.availability) {
        data.set('availabilityUpdateFlag', true)
      }
      if (this.customSkillLevel != null) {
        data.set('customSkillLevel', this.customSkillLevel)
      }
      return data

    },
    onSubmitSuccess() {
      let message = null

      if (this.action == 'save' || this.action == 'toggleEnabled') {
        location.reload()
      } else if (this.action === 'requestSkillsUpdate') {
        message = `Skill update request sent via ${(this.user['contactMethod'] === 'slack') ? 'slack' : 'email'}`
      } else if (this.action === 'requestAvailabilityUpdate') {
        message = `Availability update request sent via ${(this.user['contactMethod']=== 'slack') ? 'slack' : 'email'}`
      }

      if (message) {
        this.alertMessages.push({state: "success", message: message})
      }
    },
    onSubmitError(error) {
      this.editing = false
      this.photoUrl = this.initialPhotoUrl

      if (error?.response?.status == 413) {
        let error_message = 'Oops! File uploaded too large!'
      } else {
        error_message = 'Oops! Update failed, try again later!'
      }
      this.alertMessages.push({state: "danger", message: error_message})
    },
    onSubmit() {
      // check for validation error before submission
      this.errors = validation.errors(validation.developerProfile, this.user)

      if (this.hasErrors) {
        this.alertMessages.push({state: "danger", message: "Oops! You missed a spot!"})
        return 
      }
      
      this.isSubmittingForm = true
      const data = this.getDeveloperFormData()
      

      let config = {
        header: {'Content-Type': 'multipart/form-data'},
      }

      const url = this.userRole == 'developer' ? '/profile' : '/users/' + this.userId

      axios
        .post(url, data, config)
        .then((res) => {
          this.isSubmittingForm = false
          this.onSubmitSuccess()
        })
        .catch((error) => {
          this.isSubmittingForm = false
          this.onSubmitError(error)
        })
    },
    resetSkillLevels() {
      const skillLevels = _.fromPairs(
        (this.user.skillLevels || []).map((skillLevel) => [skillLevel.skillId, skillLevel])
      )

      for (const category of this.skillCategories) {
        for (const skill of category.skills) {
          if (skillLevels[skill.id] == null) {
            skillLevels[skill.id] = {
              skill,
              interested: false,
              level: 0,
            }
          }
        }
      }
 
      this.skillLevels = skillLevels
    },
    unmaskHourlyRate() {
      this.isHourlyRateMasked = false
    },
    toggleInterested(skillId) {
      if (this.canToggleInterested(skillId) && this.editing) {
        this.skillLevels[skillId].interested = !this.skillLevels[skillId].interested
      }
    },
    toggleApproval(status) {
      if (this.editing) {
        this.approvalStatus = !status
      }
    },
    canToggleInterested(skillId) {
      return this.skillLevels[skillId].interested || this.interestedSkillLevels.length < 3
    },
    skillLevelDescription(skillLevel) {
      return skillLevelDescription(skillLevel);
    },
    updateTooltip(event) {
      const skillName = event.target.name;
      const skillId = skillName.replace('skillLevels.', '');
      this.tooltipText = this.skillLevelDescription(this.skillLevels[skillId]);
      this.$nextTick(() => {
        $(event.target).remove('title').tooltip()
          .attr('data-bs-original-title', this.tooltipText)
          .tooltip('show');
        setTimeout(() => {
          $(event.target).tooltip('hide');
        }, 3000);
      });
    },
    hideTooltip(event) {
      $(event.target).tooltip('hide');
      this.tooltipText = '';
    },
    availabilityTooltip(availability) {
      if (!availability) {
        return ''
      }
      return this.AVAILABILITY_DESCRIPTIONS[availability]
    },
    skillLevelBadgeClass(skillLevel) {
      return skillLevelBadgeClass(skillLevel)
    },
    isImageSquare(file) {
      return new Promise((resolve, reject) => {
        try {
          const img = new Image()
          img.src = window.URL.createObjectURL(file)
          img.onload = function () {
            return resolve(parseInt(img.naturalWidth) == parseInt(img.naturalHeight))
          }
        } catch (err) {
          return reject(err)
        }
      })
    },
    async onProfilePhotoChange(e) {
      const file = e.target.files[0]
      if (!file) {
        this.photoUrl = this.initialPhotoUrl
      } else {
        // check if image dimension is square before file upload
        const result = await this.isImageSquare(file)
        if (!result) {
          this.photoUrl = this.initialPhotoUrl

          // reset file input so invalid image won't be submitted
          if (this.$refs.profilePhoto) {
            this.$refs.profilePhoto.value = null
          }
          if (this.$refs.profilePhotoMobile) {
            this.$refs.profilePhotoMobile.value = null
          }
          this.alertMessages.push({state: "danger", message: "Image should be square."})
        } else {
          loadImage.parseMetaData(file, (data) => {
            let orientation = 0
            // if exif data available, update orientation
            if (data.exif) {
              orientation = data.exif.get('Orientation')
            }

            loadImage(
              file,
              (canvas) => {
                const base64data = canvas.toDataURL('image/jpeg')
                this.photoUrl = base64data
              },
              {
                canvas: true,
                orientation,
              }
            )
          })
        }
      }
    },
  },
})