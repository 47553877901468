import Vue from 'vue';

const app = new Vue({
  el: '.page-teams',
  data: {
    allDevelopers: [],
    selectedTeamId: null,
    selectedTeamName: null,
    selectedUserId: null,
  },
  beforeMount: function() {
    if (this.$el.querySelector('[ref="allDevelopers"]')) {
      this.allDevelopers = JSON.parse(this.$el.querySelector('[ref="allDevelopers"]').value);
    }
  },
  computed: {
    availableDevelopers() {
      return this.allDevelopers.filter(
        (developer) => !developer.teamIds.includes(this.selectedTeamId)
      )
    },
  },
  methods: {
    addDeveloperToTeam(teamId, teamName) {
      this.selectedTeamId = teamId
      this.selectedTeamName = teamName
      this.selectedUserId = null
      $('#add-developer-modal').modal('show')
    },
  },
})