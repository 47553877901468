import Vue from 'vue';

const app = new Vue({
  el: '.page-signup',
    data: {
      name: '',
      password: '',
      confirmPassword: '',
    },
    computed: {
      isSignupButtonEnabled() {
        return (
          this.name.length > 0 && this.password.length > 0 && this.password === this.confirmPassword
        )
      },
    },
    beforeMount() {
      // Load the initial user name value from the value specified in the Nunjucks template
      this.name = $('input[name=name]').attr('value')
    },
})