if (window.Vue != null) {
  Vue.directive('tooltip', {
    bind(el, binding) {
      let trigger = ''

      if (binding.modifiers.focus || binding.modifiers.hover || binding.modifiers.click) {
        const t = []
        if (binding.modifiers.focus) t.push('focus')
        if (binding.modifiers.hover) t.push('hover')
        if (binding.modifiers.click) t.push('click')
        trigger = t.join(' ')
      }

      $(el).tooltip({
        title: binding.value,
        placement: binding.arg,
        trigger,
        html: Boolean(binding.modifiers.html),
      })
    },
    update(el, binding) {
      // There's no offical way currently to update the text of a
      // Bootstrap 4 tooltip _while it's showing_, so we have to
      // resort to this hacky method of updating the tooltip DOM
      // directly.
      const tooltipId = $(el).attr('aria-describedby')
      $(`#${tooltipId} .tooltip-inner`).text(binding.value)
      $(el).attr('title', binding.value).tooltip('_fixTitle').tooltip('update')
    },
    // eslint-disable-next-line no-unused-vars
    unbind(el, binding) {
      $(el).tooltip('dispose')
    },
  })
}

function updateClientAttributes() {
  const clientId = $('select[name="clientId"]').val()
  const client_attr = $('input[name="clientAttributes"]').val()
  let clientAttributes
  let attributeList

  if (client_attr) {
    attributeList = JSON.parse(client_attr)
    clientAttributes = attributeList.find((attr) => attr.id == clientId)
  }

  $("span[name='client-attributes']").hide()

  if (clientAttributes && clientAttributes.attributes.length > 0) {
    // show available client attributes beside label
    for (let i = 0; i < clientAttributes.attributes.length; i++) {
      $("label[for='id_clientId']").after(
        '&nbsp<span class="badge badge-outline bg-white mb-2" name="client-attributes"> ' +
          clientAttributes.attributes[i].name +
          ' ' +
          clientAttributes.attributes[i].emoji +
          '</span>'
      )
    }
  }
}

function filterClientContactOptionsForClient(clientId) {
  let options = []
  $('select[name="clientContactId"] option').each((index, elem) => {
    const optionClientId = $(elem).data('clientid') ? String($(elem).data('clientid')) : null
    const addNewContactOption = optionClientId == null
    const relatedOption = optionClientId === clientId

    if (addNewContactOption || relatedOption) {
      $(elem).show()
      if (relatedOption) {
        options.push(elem)
      }
    } else {
      $(elem).hide()
    }
  })
  return options
}

function updateClientContacts() {
  const clientId = $('select[name="clientId"]').val()


  if (clientId !== 'newClient') {
    let options = filterClientContactOptionsForClient(clientId)    

    let selectElement = document.getElementById('id_clientContactId')
    if (selectElement && selectElement.value === '' && options.length == 1) {
      selectElement.value = options[0].value;
    }
    $('select[name="clientContactId"]').selectpicker('refresh')
  }
}

function disableCheckboxesOnLoad() {
  $('#freshbooksCreateProject').attr('disabled', true)
  $('#githubCreateProject').attr('disabled', true)
  $('#githubInviteUsers').attr('disabled', true)
  $('#slackCreateChannel').prop('disabled', true)
  $('#slackInviteUsers').attr('disabled', true)
  $('#slackSendNotification').attr('disabled', true)
}

$(document).ready(() => {
  $("label[for='id_longName']").after(
    '<i class="mdi mdi-help-circle" data-toggle="tooltip" data-placement="right" title="' +
      'This is used as the project name in Statusfaction and Gitlab.' +
      '"></i>'
  )

  $("label[for='id_freshbooksName']").after(
    '<i class="mdi mdi-help-circle" data-toggle="tooltip" data-placement="right" title="' +
      'This is used as the Freshbooks project name and can be shorter than the main project name.' +
      '"></i>'
  )

  $("label[for='id_shortName']").after(
    '<i class="mdi mdi-help-circle" data-toggle="tooltip" data-placement="right" title="' +
      'This should be short, lowercase, and use dashes instead of spaces. This is also used for the Gitlab project url.' +
      '"></i>'
  )

  $('input.datepicker').datepicker({
    autoclose: true,
  })

  $('input.switch').bootstrapSwitch({
    size: 'mini',
  })

  $('[data-toggle="tooltip"]').tooltip()

  $('select[name="clientId"]').change(function () {
    $('.new-client').toggle(this.value === 'newClient')
    $('.new-client input').val('')
    $('select[name="clientContactId"]').val('')

    updateClientAttributes()
    updateClientContacts()
  })

  $('.new-contact').hide()

  $('select[name="clientContactId"]').change(function () {
    $('.new-contact').toggle(this.value === 'newContact')
    $('.new-contact input').val('')
  })

  // initial filter contacts for edit opportunity
  updateClientContacts()
  updateClientAttributes()

  // ==========================
  // Convert to Statusfaction Checkbox Logic
  // ==========================

  $.fn.extend({
    shouldEnable: function (shouldEnable) {
      return this.each(function () {
        if (shouldEnable) {
          this.disabled = false
        } else {
          this.disabled = true
          this.checked = false
        }
      })
    },
  })

  const checkboxSendHRRequest = $('#freshbooksCreateProject')
  const checkboxGitlabCreate = $('#githubCreateProject')
  const checkboxGitlabInvite = $('#githubInviteUsers')
  const checkboxSlackCreate = $('#slackCreateChannel')
  const checkboxSlackInvite = $('#slackInviteUsers')
  const checkboxSlackPost = $('#slackSendNotification')

  const inputFreshbooksName = $('#id_freshbooksName')
  const inputProjectName = $('#id_longName')
  const inputSlackChannelName = $('#id_shortName')

  function isNotEmpty(sel) {
    const hasValue = $(sel).val()
    return hasValue && $(sel).val().trim() != ''
  }

  function isChecked(sel) {
    return $(sel).is(':checked')
  }

  // on load
  checkboxGitlabCreate.shouldEnable(isNotEmpty(inputProjectName))
  checkboxSendHRRequest.shouldEnable(isNotEmpty(inputFreshbooksName))
  checkboxGitlabInvite.shouldEnable(isChecked(checkboxGitlabCreate))
  checkboxSlackCreate.shouldEnable(isNotEmpty(inputSlackChannelName))
  checkboxSlackInvite.shouldEnable(isChecked(checkboxSlackCreate))
  checkboxSlackPost.shouldEnable(isChecked(checkboxSlackInvite))

  // input listeners
  inputFreshbooksName.keyup(function () {
    checkboxSendHRRequest.shouldEnable(isNotEmpty(this))
  })

  inputProjectName.keyup(function () {
    checkboxGitlabCreate.shouldEnable(isNotEmpty(this))
    checkboxGitlabInvite.shouldEnable(isChecked(checkboxGitlabCreate) && isNotEmpty(this))
  })

  inputSlackChannelName.keyup(function () {
    checkboxSlackCreate.shouldEnable(isNotEmpty(this))
    checkboxSlackInvite.shouldEnable(isChecked(checkboxSlackCreate) && isNotEmpty(this))
    checkboxSlackPost.shouldEnable(isChecked(checkboxSlackInvite) && isNotEmpty(this))
  })

  // checkbox listeners
  checkboxGitlabCreate.change(function () {
    checkboxGitlabInvite.shouldEnable(isChecked(this))
  })

  checkboxSlackCreate.change(function () {
    checkboxSlackInvite.shouldEnable(isChecked(this))
    checkboxSlackPost.shouldEnable(isChecked(checkboxSlackInvite))
  })

  checkboxSlackInvite.change(function () {
    checkboxSlackPost.shouldEnable(isChecked(this))
  })

  function validateAllTextFieldsForForm(formId) {
    validateForm(formId)
    $(formId)
      .find('.input-with-feedback')
      .each(function () {
        $(this).keyup(function () {
          validateForm(formId)
        })
      })
  }

  function validateForm(formId) {
    let isInvalid = false
    const submitButton = $(formId).find("button[type='submit'")
    const textfields = $(formId).find('.input-with-feedback')

    for (let i = 0; i < textfields.length; i++) {
      if (!textfields[i].value || textfields[i].value.trim().length == 0) {
        isInvalid = true
        break
      }
    }

    submitButton.prop('disabled', isInvalid)
  }

  validateAllTextFieldsForForm('#add-team-modal')
  validateAllTextFieldsForForm('#edit-team-modal')
  validateAllTextFieldsForForm('#add-category-modal')
  validateAllTextFieldsForForm('#add-skill-modal')
})
