import Vue from 'vue';

const app = new Vue({
 el: '.page-forgot-password',
  data: {
    email: '',
  },
  computed: {
    isButtonEnabled() {
      return this.email.length > 0
    },
  },
})