$(document).ready(function() {
  $('button.delete-category').prop('disabled', false)

  $('button.delete-category').click(function() {
    $(this).prop('disabled', true)

    let id = $('#delete-category-modal').data('skill-category').id

    $.delete('/api/skillCategories/' + id, function() {
      window.location = '/skillCategories'
    })
  })

  $("#add-category-modal").on("hidden.bs.modal", function(){
    $(this).find('form')[0].reset();
  });

  $("#add-skill-modal").on("hidden.bs.modal", function(){
    $(this).find('form')[0].reset();
  });
  
  $('#add-skill-modal').on('show.bs.modal', function (event) {
    let modal = $(this)

    if (event.relatedTarget == null)
      return
  
    let addButton = $(event.relatedTarget)
    let skillCategory = addButton.closest('.skill-category').data('skill-category')

    modal.data('skill-category', skillCategory)
    modal.find('input[name="categoryId"]').val(skillCategory.id)
    modal.find('.skill-category-name').text(skillCategory.name)

    $("input[type=checkbox]").removeClass('form-control')

    let nameGroup = modal.find('input[teamId="teamId"]').closest('.form-group')

    nameGroup.addClass('has-success')
    nameGroup.removeClass('has-error')

    nameGroup.find('.help-block').remove()
  })

  $('#delete-category-modal').on('show.bs.modal', function (event) {
    let modal = $(this)
    let deleteButton = $(event.relatedTarget)
    let skillCategory = deleteButton.closest('.skill-category').data('skill-category')
  
    modal.data('skill-category', skillCategory)
    modal.find('.skill-category-name').text(skillCategory.name)
    modal.find('.skill-category-id').attr('value', skillCategory.id)
  })

    $('#edit-category-modal').on('show.bs.modal', function (event) {
    let modal = $(this)

    if (event.relatedTarget == null)
      return

    let editButton = $(event.relatedTarget)
    let skillCategory = editButton.closest('.skill-category').data('skill-category')

    modal.data('skill-category', skillCategory)
    modal.find('input[name="id"]').val(skillCategory.id)
    modal.find('input[name="name"]').val(skillCategory.name)
    modal.find('input[name="description"]').val(skillCategory.description)
    modal.find('.skill-category-name').text(skillCategory.name)
    
    let nameGroup = modal.find('input[name="name"]').closest('.form-group')

    nameGroup.addClass('has-success')
    nameGroup.removeClass('has-error')

    nameGroup.find('.help-block').remove()

    let descriptionGroup = modal.find('input[name="description"]').closest('.form-group')

    descriptionGroup.addClass('has-success')
    descriptionGroup.removeClass('has-error')

    descriptionGroup.find('.help-block').remove()
    
  })

  $('#skills-update-modal').on('show.bs.modal', function (event) {
    let modal = $(this)

    if (event.relatedTarget == null)
      return

    let row = $(event.relatedTarget)
    let skill = row.closest('.skills-table tbody tr').data(skill)

    modal.data('skill', skill)
    modal.find('input[name="id"]').val(skill.id)
    modal.find('input[name="name"]').val(skill.skill.name)
    modal.find('input[name="description"]').val(skill.skill.description)
    modal.find('.skill-name').text(skill.skill.name)
    modal.find('select[name="categoryId"]').val(skill.skill.categoryId)

    $("input[type=checkbox]").removeClass('form-control')
    
    let nameGroup = modal.find('input[name="name"]').closest('.form-group')

    nameGroup.addClass('has-success')
    nameGroup.removeClass('has-error')

    nameGroup.find('.help-block').remove()
    
    let descriptionGroup = modal.find('input[name="description"]').closest('.form-group')

    descriptionGroup.addClass('has-success')
    descriptionGroup.removeClass('has-error')

    descriptionGroup.find('.help-block').remove()
    
  })

  // on submit, check if category name is not an empty string in add category modal
  $('#add-category-error').hide()
  $('#add-category-modal button[name="action"]').on('click', function(e) {
    category_name = $('#add-category-modal input[name="name"]').val()
    if (category_name.trim().length === 0) {
      $('#add-category-error').show()
      e.preventDefault()
    }
  })

  // on submit, check if category name is not an empty string in edit category modal
  $('#edit-category-error').hide()
  $('#edit-category-modal button[name="action"]').on('click', function(e) {
    category_name = $('#edit-category-modal input[name="name"]').val()
    if (category_name.trim().length === 0) {
      $('#edit-category-error').show()
      e.preventDefault()
    }
  })

  // on submit, check if skill name is not an empty string in add skill modal
  $('#add-skill-error').hide()
  $('#add-skill-modal button[name="action"]').on('click', function(e) {
    skill_name = $('#add-skill-modal input[name="name"]').val()
    if (skill_name.trim().length === 0) {
      $('#add-skill-error').show()
      e.preventDefault()
    }
  })

  // on submit, check if skill name is not an empty string in edit skill modal
  $('#skills-update-error').hide()
  $('#skills-update-modal button[name="action"]').on('click', function(e) {
    skill_name = $('#skills-update-modal input[name="name"]').val()
    if (skill_name.trim().length === 0) {
      $('#skills-update-error').show()
      e.preventDefault()
    }
  })

})
