import Vue from 'vue';

const app = new Vue({
  el: '.page-login',
  data: {
    email: '',
    password: '',
  },
  computed: {
    isSigninButtonEnabled() {
      return this.email.length > 0 && this.password.length > 0
    },
    isGoogleButtonEnabled() {
      return this.email.length === 0 && this.password.length === 0
    },
  },
})