import Vue from 'vue';

const app = new Vue({
  el: '.page-reset-password',
  data: {
    password: '',
    confirmPassword: '',
  },
  computed: {
    isButtonEnabled() {
      return this.password.length > 0 && this.password === this.confirmPassword
    },
  },
})