$(document).ready(() => {
  // set default date range setting
  $('#id_daterange').daterangepicker({
    opens: 'left',
    locale: {
      cancelLabel: 'CLEAR',
      applyLabel: 'DONE',
      startDate: moment().format('MMMM DD, YYYY'),
      endDate: moment().format('MMMM DD, YYYY'),
      format: 'MMMM DD, YYYY',
    },
  })

  $("label[for='save-vacation']").addClass('disabled')

  $('#id_daterange').on('showCalendar.daterangepicker', function (ev, picker) {
    // set startDate and endDate value to default date range
    $('#id_startDate').val(picker.startDate.format('YYYY-MM-DD'))
    $('#id_endDate').val(picker.endDate.format('YYYY-MM-DD'))
    $("label[for='save-vacation']").removeClass('disabled')
  })

  $('#id_daterange').on('cancel.daterangepicker', function (ev, picker) {
    // clear input
    $('#id_daterange').val('')
    $("label[for='save-vacation']").addClass('disabled')
  })

  $('#id_daterange').on('apply.daterangepicker', function (ev, picker) {
    // set startDate and endDate value to newly selected date range
    $('#id_startDate').val(picker.startDate.format('YYYY-MM-DD'))
    $('#id_endDate').val(picker.endDate.format('YYYY-MM-DD'))
    $("label[for='save-vacation']").removeClass('disabled')
  })
})
