function updateSaveButton() {
  // If selected checkboxes > 0
  if ($('input:checked').length > 0) {
    $('.page-opportunity-skills__send-button').removeClass('disabled')
  } else {
    $('.page-opportunity-skills__send-button').addClass('disabled')
  }
}

$(document).ready(() => {
  $('.chosen-select').chosen()
  $('.chosen-select').removeClass('hide-until-loaded')

  $('select[name="skills"]').change(() => {
    if ($('select[name="skills"] :selected').length > 0) {
      $('.page-opportunity-skills__save-button').addClass('disabled')
      $('.page-opportunity-skills__send-button').addClass('disabled')
    } else {
      $('.page-opportunity-skills__save-button').removeClass('disabled')
      $('.page-opportunity-skills__send-button').removeClass('disabled')
    }
  })

  $('input[type="checkbox"]').change(updateSaveButton)

  updateSaveButton()

  $('.broadcast-message').hide()

  $('#skills-update-modal input[name="shouldBroadcast"]').on('click', function () {
    $('#skills-update-modal .broadcast-message').toggle()
  })

  $('#add-skill-modal input[name="shouldBroadcast"]').on('click', function () {
    $('#add-skill-modal .broadcast-message').toggle()
  })
})
