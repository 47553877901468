$(document).ready(function(){
    $('[data-bs-toggle="tooltip"]').tooltip();   

    function validateTextFormWithClassName(formId, className) {
        validateForm(formId, className)
        $(formId)
        .find(className)
        .each(function () {
        $(this).keyup(function () {
            validateForm(formId, className)
        })
        })
    }

    function validateFormWithClassName(formId, className) {
        validateForm(formId, className)
        $(formId)
        .find(className)
        .each(function () {
        $(this).change(function () {
            validateForm(formId, className)
        })
        })
    }

    function validateForm(formId, className) {
        let isInvalid = false
        const submitButton = $(formId).find("button[type='submit'")
        const textfields = $(formId).find(className)
        for (const textfield of textfields) {
            if (!textfield.value || textfield.value.trim().length == 0) {
                isInvalid = true
                break
            }
        }
        submitButton.prop('disabled', isInvalid)
    }

    $("#edit-category-modal").on("shown.bs.modal", function(){
        validateTextFormWithClassName('#edit-category-modal', '.input-with-feedback')
    });

    $("#skills-update-modal").on("shown.bs.modal", function(){
        validateFormWithClassName('#skills-update-modal', '.required')
    });
});
