// @ts-nocheck
const _ = require('lodash')
const validator = require('validator')

export const NAME_REGEX = /^([a-zA-Z0-9() .-]\s?)*$/gm
export const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g
export const EMOJI_REGEX = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g

// eslint-disable-next-line consistent-return
const expect = (condition, error) => {
  if (condition === undefined) {
    throw new Error('Expected condition, got undefined')
  } else if (_.isFunction(condition)) {
    return (field) => {
      if (field && field.length > 0 && !condition(field)) throw new Error(error)
    }
  } else if (!condition) {
    throw new Error(error)
  }
}

function matches(field, regex, error) {
  const isInvalid = field && field.length > 0 && !field.match(regex)

  if (isInvalid) {
    throw new Error(error)
  }
}

function noMatch(field, regex, error) {
  const isInvalid = field && field.length > 0 && field.match(regex)

  if (isInvalid) {
    throw new Error(error)
  }
}

const required = (name) => (field) => {
  if (field == null || field.length === 0) {
    throw new Error(`${name} is required`)
  }
}

const number = ({ whole = false, min = null, max = null } = {}) => (field) => {
  if (field === '') {
    return
  }

  if (isNaN(field)) {
    throw new Error('Expected a number')
  }

  const n = Number(field)

  if (whole && !_.isInteger(n)) {
    throw new Error('Expected a whole number')
  }

  if (min != null && n < min) {
    throw new Error(`Expected a number greater than or equal to ${min}`)
  }

  if (max != null && n > max) {
    throw new Error(`Expected a number less than or equal to ${max}`)
  }
}

const maxlength = (length) => (field) => {
  if (field && field.length > length) {
    throw new Error('This is too long!')
  }
}

// eslint-disable-next-line no-shadow
const regex = (regex, error) => (field) => matches(field, regex, error)
// eslint-disable-next-line no-shadow
const noRegex = (regex, error) => (field) => noMatch(field, regex, error)

const email = expect(validator.isEmail, 'Email address is invalid')
const url = expect(validator.isURL, 'URL is invalid')
const name = regex(NAME_REGEX, 'Name is invalid')
const phone = regex(PHONE_REGEX, 'Phone number is invalid')
const noEmojis = noRegex(EMOJI_REGEX, 'Emojis are not allowed')

export const developerProfile = {
  name: [required('Name'), name, noEmojis],
  email: [required('Email'), email, noEmojis],
  phoneNumber: [phone, noEmojis],
  rate: number({ whole: true, min: 0, max: 999999 }),
  bio: [maxlength(200), noEmojis],
  socialLinkedIn: url,
  socialTwitter: url,
  socialFacebook: url,
}

export function validate(form, data) {
  for (const [fieldName, validation] of Object.entries(form)) {
    if (data[fieldName] !== undefined) {
      const validateArray = _.isArray(validation) ? validation : [validation]

      for (const validateField of validateArray) {
        validateField(data[fieldName])
      }
    }
  }
}

export function errors(form, data) {
  // eslint-disable-next-line no-shadow
  const errors = {}

  for (const [fieldName, validation] of Object.entries(form)) {
    const validateArray = _.isArray(validation) ? validation : [validation]
    errors[fieldName] = []

    for (const validateField of validateArray) {
      try {
        validateField(data[fieldName])
      } catch (error) {
        errors[fieldName].push(error.message)
      }
    }
  }

  for (const fieldName of Object.keys(data)) {
    if (errors[fieldName] == null) {
      errors[fieldName] = []
    }
  }

  return errors
}
