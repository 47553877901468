export const SKILL_LEVEL_DESCRIPTIONS = [
  "0. No experience (0 Hours)",
  "1. Beginner—Basic understanding, limited practical experience (1-100 hours)",
  "2. Novice—Basic syntax knowledge, limited project experience (101-500 hours)",
  "3. Intermediate—Comfortable with language fundamentals, some project experience (501-1,000 hours | 1-3mo)",
  "4. Competent—Proficient in language basics, participated in multiple projects (1,001-2,000 hours | 6-12mo)",
  "5. Skilled—Solid understanding, capable of independent development (2,001-4,000 hours | 1-2y)",
  "6. Proficient—Advanced knowledge, consistently delivers high-quality work (4,001-6,000 hours | 2-3y)",
  "7. Experienced—Seasoned developer, adept at solving complex problems (6,001-8,000 hours  | 3-4y)",
  "8. Advanced—Expert-level understanding, mentors others in the skill (8,001-12,000 hours | 4-6y)",
  "9. Master—Exceptional expertise, recognized authority in the community (12,001-16,000 hours | 6-8y)",
  "10. Thought Leader—Unparalleled mastery, contributes significantly to the communit (16,001+ hours | 8y+)"
]

export function skillLevelDescription(skillLevel) {
  return SKILL_LEVEL_DESCRIPTIONS[skillLevel.level]
}

export const MAX_SKILL_LEVEL = 10

export function skillLevelBadgeClass(skillLevel) {
  if (skillLevel.level <= 2) {
    return `badge-danger`
  } else if (skillLevel.level <= 4) {
    return `badge-warning`
  } else if (skillLevel.level <= 6) {
    return `badge-success`
  } else if (skillLevel.level <= 8) {
    return `badge-info`
  } else {
    return 'badge-primary'
  }
}
